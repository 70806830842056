export interface IFooterLinksGroup {
  groupId: number;
  links: IFooterLink[];
}

export interface IFooterLink {
  parentId: number;
  linkId: number;
  linkLabel: string;
  linkUrl: string | null;
}

export const FooterLinkObjects = [
  {
    groupId: 5,
    links: [
      {
        parentId: 5,
        linkId: 1,
        linkLabel: 'Digital Tools',
        linkUrl: '/benefits/digital-tools',
      },
      {
        parentId: 5,
        linkId: 2,
        linkLabel: 'Logistics Explorer',
        linkUrl: 'https://www.searates.com/services/logistics-explorer/',
      },
      {
        parentId: 5,
        linkId: 3,
        linkLabel: 'Tracking System',
        linkUrl: '/benefits/digital-tools/tracking-system',
      },
      {
        parentId: 5,
        linkId: 4,
        linkLabel: 'Ship Schedules',
        linkUrl: 'https://www.searates.com/services/schedules/',
      },
      {
        parentId: 5,
        linkId: 5,
        linkLabel: 'Cargoes Flow',
        linkUrl: '/benefits/digital-tools/cargoes-flow',
      },
      {
        parentId: 5,
        linkId: 6,
        linkLabel: 'Freight Forwarding Software',
        linkUrl: '/benefits/digital-tools/searates-erp',
      },
    ],
  },
  {
    groupId: 1,
    links: [
      {
        parentId: 1,
        linkId: 1,
        linkLabel: 'Company',
        linkUrl: '/company/vision-strategy',
      },
      {
        parentId: 1,
        linkId: 2,
        linkLabel: 'Vision & Strategy',
        linkUrl: '/company/vision-strategy',
      },
      {
        parentId: 1,
        linkId: 3,
        linkLabel: 'Contact Us',
        linkUrl: '/company/contact-us',
      },
      {
        parentId: 4,
        linkId: 4,
        linkLabel: 'Membership Regulations',
        linkUrl: '/resources/membership-regulations',
      },
      {
        parentId: 4,
        linkId: 5,
        linkLabel: 'Code of Ethics',
        linkUrl: '/resources/code-of-ethics',
      },
      {
        parentId: 4,
        linkId: 6,
        linkLabel: 'Privacy Policy',
        linkUrl: '/resources/privacy-policy',
      },
      {
        parentId: 4,
        linkId: 7,
        linkLabel: 'Terms and Conditions',
        linkUrl: '/resources/terms-and-conditions',
      },
      {
        parentId: 1,
        linkId: 8,
        linkLabel: 'FAQs',
        linkUrl: '/faqs',
      },
      {
        parentId: 4,
        linkId: 9,
        linkLabel: 'Blacklist',
        linkUrl: '/resources/blacklist',
      },
    ],
  },
  {
    groupId: 2,
    links: [
      {
        parentId: 2,
        linkId: 1,
        linkLabel: 'Benefits',
        linkUrl: '/benefits',
      },
      {
        parentId: 2,
        linkId: 2,
        linkLabel: 'Shipping Leads',
        linkUrl: '/benefits/shipping-leads',
      },
      {
        parentId: 2,
        linkId: 3,
        linkLabel: 'Networking',
        linkUrl: '/benefits/networking',
      },
      {
        parentId: 2,
        linkId: 4,
        linkLabel: 'Support',
        linkUrl: '/benefits/support',
      },
      // {
      //   parentId: 2,
      //   linkId: 6,
      //   linkLabel: 'Digital Tools',
      //   linkUrl: '/benefits/digital-tools',
      // },
      {
        parentId: 2,
        linkId: 5,
        linkLabel: 'Payment Protection',
        linkUrl: '/benefits/payment-protection',
      },
      {
        parentId: 2,
        linkId: 6,
        linkLabel: 'Brand Reliability',
        linkUrl: '/benefits/brand-reliability',
      },
      {
        parentId: 3,
        linkId: 7,
        linkLabel: 'Trade Finance',
        linkUrl: '/services/trade-finance',
      },
    ],
  },
  // {
  //   groupId: 3,
  //   links: [
  //     {
  //       parentId: 3,
  //       linkId: 1,
  //       linkLabel: 'Services',
  //       linkUrl: '/services',
  //     },
  //     {
  //       parentId: 3,
  //       linkId: 3,
  //       linkLabel: 'SNPL',
  //       linkUrl: '/services/snpl',
  //     },
  //     {
  //         parentId: 3,
  //         linkId: 3,
  //         linkLabel: 'CARGOES Runner',
  //         linkUrl: '/services/cargoes-runner',
  //     },
  //     {
  //       parentId: 3,
  //       linkId: 4,
  //       linkLabel: 'CARGOES Flow',
  //       linkUrl: '/services/cargoes-flow',
  //     },
  //     {
  //       parentId: 3,
  //       linkId: 5,
  //       linkLabel: 'Tracking System',
  //       linkUrl: '/services/tracking-system',
  //     },
  //   ],
  // },
  {
    groupId: 4,
    links: [
      {
        parentId: 4,
        linkId: 1,
        linkLabel: 'Resources',
        linkUrl: '/resources',
      },
      {
        parentId: 4,
        linkId: 2,
        linkLabel: 'Freight Dictionary',
        linkUrl: '/freight-dictionary',
      },
      {
        parentId: 4,
        linkId: 3,
        linkLabel: 'How to Become a Freight Forwarder',
        linkUrl: '/freight-forwarder',
      },
      {
        parentId: 4,
        linkId: 4,
        linkLabel: 'Bill of Lading',
        linkUrl: '/resources/bill-of-lading',
      },
      {
        parentId: 4,
        linkId: 5,
        linkLabel: 'Demurrage and Detention',
        linkUrl: '/resources/demurrage-and-detention',
      },
      {
        parentId: 1,
        linkId: 6,
        linkLabel: 'Blog',
        linkUrl: '/blog',
      },
    ],
  },
];
